<template>
  <div class="setting">
    <el-form label-width="130px" size="mini">
      <el-form-item label="是否开启分销设置">
        <el-switch
          v-model="dealerModule"
          :active-value="1"
          :inactive-value="0"
          :disabled="enableDisabled"
          @change="enableModule"
        ></el-switch>
      </el-form-item>

      <template v-if="dealerModule">
        <el-form-item label="佣金类型" required>
          <el-radio-group v-model="submitData.CommissionType">
            <el-radio
              v-for="item in commisTypes"
              :key="item.Value"
              :label="item.Value"
            >
              {{ item.Name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="分销层级" required>
          <el-radio-group v-model="submitData.MaxLevelNum">
            <el-radio
              v-for="item in [
                { Name: '一级分销', Value: 1 },
                { Name: '二级分销', Value: 2 },
                { Name: '三级分销', Value: 3 },
              ]"
              :key="item.Value"
              :label="item.Value"
            >
              {{ item.Name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="一级佣金比例" required>
          <el-input
            v-model="submitData.FirstCommisRate"
            type="number"
            :min="0"
            :max="100"
            @change="onChangeInput($event, 'FirstCommisRate')"
          ></el-input>
          <span class="tips">(佣金比例范围 0% ~ 100%)</span>
        </el-form-item>

        <el-form-item
          v-if="submitData.MaxLevelNum > 1"
          label="二级佣金比例"
          required
        >
          <el-input
            v-model="submitData.SecondCommisRate"
            type="number"
            :min="0"
            :max="100"
            @change="onChangeInput($event, 'SecondCommisRate')"
          ></el-input>
          <span class="tips">(佣金比例范围 0% ~ 100%)</span>
        </el-form-item>

        <el-form-item
          v-if="submitData.MaxLevelNum > 2"
          label="三级佣金比例"
          required
        >
          <el-input
            v-model="submitData.ThirdCommisRate"
            type="number"
            :min="0"
            :max="100"
            @change="onChangeInput($event, 'ThirdCommisRate')"
          ></el-input>
          <span class="tips">(佣金比例范围 0% ~ 100%)</span>
        </el-form-item>

        <el-form-item label="分销海报背景图">
          <div class="code">
            <el-upload
              class="upload-demo"
              :action="uploadLogoUrl"
              :limit="1"
              :headers="headers"
              :on-success="onUploadSuccess"
              :on-error="onUploadError"
              :before-upload="beforeAvatarUpload"
              :on-exceed="onUploadExceed"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，且不超过500kb，尺寸 宽750像素
                高大于(等于)1200像素
              </div>
            </el-upload>
            <div class="qrcode">
              <el-image
                v-if="extra.QrcodeBackdropUrl"
                :src="extra.QrcodeBackdropUrl"
              ></el-image>
              <div class="bottom">
                <i class="el-icon-delete" @click="onDeleteImage"></i>
              </div>
            </div>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" :disabled="saveDiabled" @click="saveSetting"
            >保存</el-button
          >
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>

<script>
import Dealer from "@/api/dealer.js";

import { baseURL } from "@/utils/axios.js";

export default {
  data() {
    return {
      uploadLogoUrl: baseURL + `/dealer/upload_backdrop`,
      headers: {
        acctoken: window.sessionStorage.getItem("token"),
      },

      dealerModule: 0,
      FirstCommisRateCopy: 0,
      SecondCommisRateCopy: 0,
      ThirdCommisRateCopy: 0,
      submitData: {
        CommissionType: 0,
        FirstCommisRate: 0,
        MaxLevelNum: 0,
        SecondCommisRate: 0,
        ThirdCommisRate: 0,
        Qrcode: {
          Backdrop: "",
        },
      },
      extra: {
        QrcodeBackdropUrl: "",
      },
      commisTypes: [], // 分销类型列表
      enableDisabled: false,
      saveDiabled: false,
    };
  },

  mounted() {
    this.initSetting();
  },

  methods: {
    // 初始化设置
    async initSetting() {
      try {
        let { data } = await Dealer.initSetting();
        let { DealerModule, commisTypes, setting, extra } = data;
        this.dealerModule = DealerModule;
        this.commisTypes = commisTypes;
        this.submitData = setting;
        this.extra = extra;
        this.FirstCommisRateCopy = JSON.parse(
          JSON.stringify(setting.FirstCommisRate)
        );
        this.SecondCommisRateCopy = JSON.parse(
          JSON.stringify(setting.SecondCommisRate)
        );
        this.ThirdCommisRateCopy = JSON.parse(
          JSON.stringify(setting.ThirdCommisRate)
        );
      } catch (err) {
        console.log(err);
      }
    },

    // 开启或关闭模块
    async enableModule(event) {
      try {
        this.enableDisabled = true;
        let { errcode } = await Dealer.enableModule({
          enable: event,
        });
        if (errcode == 0) {
          this.$message.success(event ? "开启成功" : "关闭成功");
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.enableDisabled = false;
      }
    },

    // 保存设置
    async saveSetting() {
      try {
        let { errcode } = await Dealer.saveSetting(this.submitData);
        if (errcode == 0) {
          this.$message.success("保存成功");
          this.initSetting();
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 输入框改变事件
    onChangeInput(event, keyName) {
      let reg = /[-|.]/g;
      if (reg.test(event)) {
        this.$message.error("佣金比例范围 0 - 100");
        this.submitData[keyName] = this[keyName + "Copy"];
        return;
      }
      this.submitData[keyName] = Number(event);
    },

    // 上传logo成功
    onUploadSuccess(event) {
      let { uri, url } = event.data;
      this.submitData.Qrcode.Backdrop = uri;
      this.extra.QrcodeBackdropUrl = url;
    },
    // 上传文件限制事件 (共有的)
    beforeAvatarUpload(file) {
      return new Promise((resolve, reject) => {
        const isJPG = file.type === "image/jpeg";
        const isGIF = file.type === "image/gif";
        const isPNG = file.type === "image/png";
        const isBMP = file.type === "image/bmp";
        const isLt2M = file.size / 1024 / 1024 < 2;
        const reader = new FileReader();

        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            if (img.width > 750 || img.height < 1200) {
              this.$message.error("上传图片尺寸不符合要求");
              reject()
            } else if (!isJPG && !isGIF && !isPNG && !isBMP) {
              this.$message.error("上传图片必须是JPG/GIF/PNG/BMP 格式!");
              reject()
            } else if (!isLt2M) {
              this.$message.error("上传图片大小不能超过 2MB!");
              reject()
            }

            resolve(isJPG || isBMP || isGIF || isPNG) && isLt2M;
          };
          img.src = e.target.result;
        };

        reader.readAsDataURL(file);
      });
    },
    // 上传文件超出数量 (共有的)
    onUploadExceed(event) {
      this.$message.error("上传文件超出数量!");
    },
    // 上传失败 (共有的)
    onUploadError() {
      this.$message.error("上传图片失败!");
    },
    // 删除logo
    onDeleteImage() {
      this.extra.QrcodeBackdropUrl = "";
      this.submitData.Qrcode.Backdrop = "";
    },
  },
};
</script>

<style lang="less" scoped>
.setting {
  padding-top: 20px;
  .el-form {
    .el-form-item {
      .el-input {
        width: 200px;
      }

      .code {
        display: flex;
        .qrcode {
          position: relative;
          .el-image {
            width: 150px;
            height: 150px;
            margin-left: 20px;
            border: 1px solid #eeeeee;
            border-radius: 5px;
          }
          .bottom {
            position: absolute;
            bottom: 15px;
            left: 20px;
            width: 150px;
            height: 150px;
            background-color: rgba(0, 0, 0, 0.3);
            display: none;
            border-radius: 5px;
            color: #ffffff;
            font-size: 30px;
            i {
              margin-top: 60px;
              margin-left: 60px;
            }
          }
        }
        .qrcode:hover .bottom,
        .qrcode:hover .bottom {
          display: block;
        }
      }
    }
  }

  .tips {
    margin-left: 10px;
    color: #888;
    font-size: 12px;
  }
}
</style>
